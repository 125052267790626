.authSection {
  background-color: #f8c601;
  padding:   30px;
  min-height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.authInnerSection {
  width:  100%; 
  max-width: 1000px;
  background:  #ffffff ;
  border-radius: 20px;
}

.authSection-left {
  text-align: center;  
  position: relative;
}

.authSection-left img {
  margin-bottom: 20px;
  width: 100%;
  max-width:  300px;
}

.authSection-left button {
  border: none;
  border-radius: 1.5rem;
  padding: 8px 35px;
  font-weight: bold;
  height: auto;
  line-height: normal;
  font-size: 14px;
}

.authSection-right {
  background: #d6e6f3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0 20px 20px 0;
}

.authSection-right .authSection-heading {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 0;
}

.authSection .lang_button{
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 99;
}

.react-tel-input .form-control{
  padding-right: 45px;
}

@media screen and (max-width: 767px) {
  .authSection {
    padding: 30px;
    display: block;
    height: auto;
  }

  .authSection-right {
    display: block;
    border-radius: 0 0 20px 20px;
  }
}